<template>
  <div>

    
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      striped
      :busy="loading"
      selectable
      stacked="sm"
      @row-selected="onRowSelected"
      select-mode="single"
      ref="entidadeTable"
      show-empty
    >
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #empty>
        <empty-state>
          <div>
            Nenhum
            {{ tipo }} cadastrado, clique em
            <b-btn
              @click="$emit('novo')"
              variant="primary"
              size="sm"
              class="mx-2"
            >
              <i class="fas fa-plus"></i> {{ nomeEntidade }}</b-btn
            >
            para cadastrar.
          </div>
        </empty-state>
      </template>
      <template #cell(razao_social)="{ item }">
        <div class="text-left">
          <b-avatar class="mr-2" :src="formataUrl(item.foto_entidade)" />
          {{ item.razao_social }}
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item.id)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
      <template #cell(telefones)="row">
        <div
          v-for="tel in row.item.telefones"
          :key="`telefone_${row.item.telefones.indexOf(tel)}`"
          class="d-block text-truncate"
        >
          {{ tel.telefone }}
        </div>
      </template>
      <template #cell(endereco)="row">
        <div
          v-for="end in row.item.enderecos"
          :key="`endereco_${row.item.enderecos.indexOf(end)}`"
          class="d-block text-truncate"
        >
          {{ end.cidade }} - {{ end.uf }}
        </div>
      </template>
      <template #cell(status)="row">
        <div
          :class="[
            'd-block text-truncate badge',
            row.item.status == 1
              ? 'badge-pill badge-primary'
              : 'badge-pill badge-secondary',
          ]"
        >
          {{ row.item.status | status }}
        </div>
      </template>
      <template #cell(especialidadesOBJ)="row">
        <div class="text-left">
                
          <div
            v-for="espec in row.item.especialidadesOBJ"
            :key="`${row.item.id}_${espec.especialidade}`"
            class="d-block text-truncate badge badge-pill badge-primary mb-1"
          >
            <i class="fa fa-stethoscope mr-1"></i>{{ espec.especialidade }}
          </div>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="paginacao.total_registros"
      :per-page="paginacao.registros_por_pagina"
      @change="mudarPagina"
    >
    </b-pagination>
  </div>
</template>

<script>
import EntidadesLib from "../../libs/EntidadesLib";
import moment from "moment";
import FilesLib from "../../libs/FilesLib";
import EmptyState from "../common/EmptyState.vue";

export default {
  components: {
    EmptyState,
  },
  props: {
    onFiltrar: Function,
    filtros: Object,
    onEditar: Function,
    tipo: String,
  },
  data() {
    return {
      loading: false,
      lista: [],
      campos: [
        { key: "id", label: "Código", sortable: true },

        { key: "status", label: "Status", sortable: true },
        { key: "telefones", label: "Telefone", sortable: true },
        { key: "endereco", label: "Cidade", sortable: true },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 12,
        pagina_atual: 1,
        total_registros: 0,
      },
      token: this.$store.state.auth.user.token,
    };
  },
  mounted() {
    // console.log(this.camposPara()[this.tipo]);
    this.carregaCampos();
    this.carregar();
  },
  methods: {
    mudarPagina(e) {
      this.paginacao.pagina_atual = e;
      this.$nextTick();
      this.carregar();
    },
    camposPara() {
      const medico = [
        { key: "razao_social", label: "Médico", sortable: true },
        { key: "email", label: "E-mail", sortable: true },
        { key: "especialidadesOBJ", label: "Especialidades", sortable: true },
      ];

      const paciente = [
        { key: "razao_social", label: "Paciente", sortable: true },
        { key: "email", label: "E-mail", sortable: true },
        { key: "convenioNome", label: "Convênio", sortable: true },
        // { key: "especialidadesOBJ", label: "Especialidades", sortable: true },
      ];
      const funcionario = [
        { key: "razao_social", label: "Funcionário", sortable: true },
        { key: "email", label: "E-mail" },
        { key: "convenioNome", label: "Convênio" },
        // { key: "especialidadesOBJ", label: "Especialidades", sortable: true },
      ];

      const convenio = [
        { key: "razao_social", label: "Empresa", sortable: true },
      ];
      const fornecedor = [
        { key: "razao_social", label: "Empresa", sortable: true },
      ];
      const laboratorio = [
        { key: "razao_social", label: "Empresa", sortable: true },
      ];

      return {
        medico,
        paciente,
        convenio,
        fornecedor,
        laboratorio,
        funcionario,
      };
    },
    async filtrar(filtro) {
      this.filtro = filtro;
      if (this.$props.onFiltrar) {
        this.$props.onFiltrar(this.filtro);
      }
    },
    formataUrl(url) {
      // let url
      // this.$forceUpdate();

      if (url && url.indexOf("blob") >= 0) {
        return url;
      }

      return (
        FilesLib.downlaodFile(url, this.token) ||
        require("@/assets/img/no-file.png")
      );
    },
    carregaCampos() {
      // console.log(this.camposPara()[this.tipo])
      this.campos = [
        this.campos[0],
        ...this.camposPara()[this.tipo],
        ...this.campos.splice(1, this.campos.length),
      ];
    },
    async carregar() {
      this.loading = true;

      await this.$nextTick();

      try {
        let result = await EntidadesLib.getResumido(
          null,
          this.paginacao,
           this.filtros,
          this.tipo
         
        );
        this.paginacao = result.pagination;
        this.lista = result.entidades.map((ent) => {
          return {
            ...ent,
            dt_nascimento: moment(ent.dt_nascimento).format("YYYY-MM-DD"),
          };
        });
      } catch (error) {
        console.warn(error);
        this.$swal.fire({
          title: `Erro ao buscar ${this.tipo}!\n + ${error.message}`,
          icon: "error",
          toast: true,
          timer: 2500,
          position: "top-end",
          showConfirmButton: false,
        });
      }

      this.loading = false;
    },
    onRowSelected(row) {
      if (row.length > 0) {
        this.editar(row[0].id);
        this.$refs.entidadeTable.clearSelected();
      }
    },
    async editar(item) {
      if (item) {
        if (this.$props.onEditar) {
          this.$props.onEditar(item);
        }
      }
    },
    async excluir(Entidade) {
      if (Entidade) {
        this.$swal
          .fire({
            title: `Você tem certeza que deseja excluir este ${this.tipo}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (res) => {
            if (res.isConfirmed) {
              try {
                const result = await EntidadesLib.del(Entidade);
                // console.log(result);
                if (result && result.success === true) {
                  this.$swal.fire({
                    title: `${this.tipo} excluído com sucesso!`,
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  });
                  await this.carregar();
                } else {
                  // console.log(result);
                  this.$swal.fire({
                    title: `Erro ao excluir! ${result.message}`,
                    icon: "error",
                    toast: true,
                    timer: 10000,
                    position: "top-end",
                    showConfirmButton: false,
                  });
                }
              } catch (error) {
                console.warn(error);
                this.$swal.fire({
                  title: `Erro ao excluir ${this.tipo}!`,
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
  },
  computed: {
    // tipo() {
    // },
    nomeEntidade() {
      const path = this.$route.path.split("/");
      let nome =
        path[path.length - 1].charAt(0).toUpperCase() +
        path[path.length - 1].slice(1);
      if (nome.charAt(nome.length - 1) == "s") nome = nome.split("s").join("");
      return nome;
    },
  },
  watch: {
    tipo: function() {
      this.carregar();

      // console.log(this.tipo);
      (this.$emit('resetFilters')),
        (this.campos = [
          { key: "id", label: "Código", sortable: true },

          { key: "status", label: "Status", sortable: true },
          { key: "telefones", label: "Telefone", sortable: true },
          { key: "endereco", label: "Cidade", sortable: true },
          { key: "actions", label: "" },
        ]),
        this.carregaCampos();
    },
    filtros: {
      deep: true,
      async handler() {
        await this.carregar();
      },
    },
    loading(newV){
      this.$emit('loading', newV)
    }
  },
};
</script>

<style></style>
