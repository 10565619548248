<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h4 @click="controlarVisibilidade()" style="cursor: pointer;">
          {{nomeComponente}}
          <span
            v-b-popover.hover="popoverConfig"
            :class="[
              visible
                ? 'fas fa-caret-square-up align-bottom text-danger'
                : 'fas fa-caret-square-down align-bottom text-danger',
            ]"
          ></span>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-collapse id="collapse-1" class="mt-2" :visible="visible">
          <slot />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            visible: false,
        }
    },
  props: {
    startVisible: {
      type: Boolean,
      required: false,
    },
    nomeComponente:{
        type:String,
        required:false
    },
    iconeClasse:{
        type:String,
        required:false
    },
    ownVisible:{
        type: Boolean,
        required: false
    }
  },
  mounted(){
     if (this.startVisible) {
         this.visible = this.startVisible
         delete this.startVisible
     } 
  },
  methods:{
      controlarVisibilidade(){
          if (this.ownVisible) {
              this.visible = !this.ownVisible
          }else{
              this.visible = !this.visible
          }
          this.$emit('visibleChanged', this.visible)

      }
  },
  computed: {
    popoverConfig() {
     
      return {
        html: true,
        title: () => {
     
          return (
            (this.visible
              ? `Esconder ${this.nomeComponente?this.nomeComponente:'' }` 
              : `Exibir ${this.nomeComponente?this.nomeComponente:'' }`) +
            ` <i class="${this.iconeClasse}"></i>`
          );
        },
        content: () => {
          // Note this is called only when the popover is opened
          return this.visible
            ? "Clique para esconder :)"
            : "Clique para exibir";
        },
      };
    },
  },
};
</script>

<style></style>
