<template>
  <div>
    <b-form @submit.stop.prevent="salvarEndereco()">
      <b-form-group>
        <div class="row">
          <div class="col-8 col-sm-6">
            <label> CEP</label>
            <b-input
              id="inputCep"
              ref="cep"
              type="text"
              placeholder="CEP"
              v-model="$v.endereco.cep.$model"
              :state="validateState('cep')"
              v-mask="'#####-###'"
            />

            <b-form-invalid-feedback
              >Informe um CEP válido
            </b-form-invalid-feedback>
          </div>
          <div class="col-4 d-flex align-self-center mt-4" v-if="loading">
            <strong>Buscando...</strong>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-6 col-sm-3">
            <b-form-group>
              <label>UF</label>
              <b-form-select
                :options="uf"
                @select="handleCodMunicipio"
                v-model="$v.endereco.uf.$model"
                :state="validateState('uf')"
              ></b-form-select>
              <b-form-invalid-feedback
                >Informe uma UF válida
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-6 col-sm-3 ml-0 pl-sm-4 pr-1">
            <b-form-group>
              <div class="d-flex justify-content-around align-items-baseline">
                <label>Cod. Cidade</label>
                <b-btn
                  @click="handleCodMunicipio"
                  v-if="!endereco.codCidade && !loadingMunicipio"
                  size="sm"
                  variant="transparent"
                >
                  <i class="fas fa-search"></i>
                </b-btn>
                <i
                  class="fas fa-exclamation-circle text-danger"
                  v-if="codCidadeError"
                ></i>

                <b-spinner
                  v-if="loadingMunicipio"
                  small
                  type="grow"
                ></b-spinner>
              </div>
              <b-form-input
                placeholder="Cód. Cidade"
                class="text-center"
                v-model="endereco.codCidade"
              ></b-form-input>
              <small class="text-center  text-muted">
                <div class="text-nowrap">* Obrigatório para emitir NFSE's</div>
              </small>
            </b-form-group>
          </div>

          <div class="col-12 col-sm-6 pl-sm-0 ml-sm-0">
            <label>Cidade</label>
            <b-form-input
              @focusout="handleCodMunicipio"
              :state="validateState('cidade')"
              v-model="$v.endereco.cidade.$model"
            ></b-form-input>

            <b-form-invalid-feedback
              :force-show="
                typeof validateState('cidade') == 'boolean' &&
                  !validateState('cidade')
              "
              >Informe uma cidade válida
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-7">
            <label>Bairro</label>
            <b-input
              ref="bairro"
              type="text"
              placeholder="Bairro"
              v-model="$v.endereco.bairro.$model"
              :state="validateState('bairro')"
            />
            <b-form-invalid-feedback
              >Informe um bairro válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="showTipoLogradouro">
        <div class="row">
          <div class="col-12 col-sm-7">
            <label>Tipo Logradouro</label>
            <b-form-select
              v-model="endereco.tipoLogradouro"
              :options="tiposLogradouro"
            ></b-form-select>
          </div>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-8">
            <label>Logradouro</label>
            <b-input
              ref="logradouro"
              type="text"
              placeholder="Logradouro"
              v-model="$v.endereco.logradouro.$model"
              :state="validateState('logradouro')"
            />
            <b-form-invalid-feedback
              >Informe um logradouro válido
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-4">
            <label>Numero</label>
            <b-input
              ref="numero"
              type="text"
              placeholder="Numero"
              v-model="$v.endereco.numero.$model"
              :state="validateState('numero')"
            />
            <b-form-invalid-feedback
              >Informe um numero válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label>Complemento</label>
            <b-input
              ref="complemento"
              type="text"
              placeholder="Complemento"
              v-model="endereco.complemento"
            />
            <b-form-invalid-feedback
              >Informe um complemento válido
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <b-form-group v-if="consultorio">
        <div class="row">
          <div class="col">
            <b-form-checkbox
              v-model="endereco.consultorio"
              :unchecked-value="0"
              :value="1"
              class="form-control d-flex justify-content-center"
              switch
              >Consultório</b-form-checkbox
            >
            <span class="text-muted text-lg">
              Caso seja um consultório, marque acima.
            </span>
          </div>
        </div>
      </b-form-group>

      <div class="bottom-actions" style="position: unset">
        <hr class="mb-2" />
        <b-btn variant="primary" class="mr-3" type="submit" :disabled="loading"
          ><i class="fas fa-save" v-if="!loading"></i>
          <b-spinner v-if="loading" small></b-spinner> Salvar</b-btn
        >
        <b-btn variant="warning" @click="fecharModal()"
          ><i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EnderecosLib from "../../libs/EnderecosLib";
// import EnderecoLib from "@/libs/EnderecosLib";
// import BotoesSalvarCancelar from '@/components/Custom/BotoesSalvarCancelar.vue'
//import SelectCidades from "../common/SelectCidades.vue";
import { buscarCEP } from "../../utils/cep";

export default {
  mixins: [validationMixin],
  components: {
    // BotoesSalvarCancelar
    //SelectCidades,
  },
  props: {
    enderecoEdit: { Type: Object },
    consultorio: { type: Boolean, deafult: false },
    showTipoLogradouro: { type: Boolean, deafult: false }
  },
  data() {
    return {
      hasErrors: [],
      enderecoCep: {},
      loadingMunicipio: false,
      tiposLogradouro: [
        "Alameda",
        "Avenida",
        "Chácara",
        "Colônia",
        "Condomínio",
        "Estância",
        "Estrada",
        "Fazenda",
        "Praça",
        "Prolongamento",
        "Rodovia",
        "Rua",
        "Sítio",
        "Travessa",
        "Vicinal",
        "Eqnp"
      ],
      endereco: {
        cep: "",
        codCidade: "",
        cidade: "",
        uf: "",
        bairro: "",
        tipoLogradouro: "Rua",
        logradouro: "",
        numero: "",
        complemento: "",
        consultorio: 0
      },
      codCidadeError: false,
      loading: false,
      uf: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO"
      ]
    };
  },
  validations: {
    endereco: {
      cep: {
        async validaCep() {
          // console.log(await this.verificaCep());
          return Boolean(await this.verificaCep());
        }
      },
      cidade: {
        required
      },
      uf: {
        required
      },
      bairro: {
        required
      },
      logradouro: {
        required
      },
      numero: {
        required
      }
    }
  },
  mounted() {
    if (this.enderecoEdit.cep) {
      this.endereco = Object.assign({}, this.enderecoEdit);
    }
  },
  methods: {
    fecharModal() {
      this.$bvModal.hide("modal-endereco");
    },
    tratarCampos() {
      this.endereco.bairro =
        typeof this.endereco.bairro === "string"
          ? this.endereco.bairro.toUpperCase()
          : "";
      this.endereco.cidade =
        typeof this.endereco.cidade === "string"
          ? this.endereco.cidade.toUpperCase()
          : "";
      this.endereco.logradouro =
        typeof this.endereco.logradouro === "string"
          ? this.endereco.logradouro.toUpperCase()
          : "";
      this.endereco.complemento =
        typeof this.endereco.complemento === "string"
          ? this.endereco.complemento.toUpperCase()
          : "";
    },
    async salvarEndereco() {
      this.$v.endereco.$touch();
      if (this.$v.endereco.$anyError) {
        return;
      }
      this.tratarCampos();
      // console.log("teste")
      this.$emit("salvar", this.endereco);
    },
    validateState(name) {
      //console.log(name, this.$v.endereco[name]);
      const { $dirty, $error } = this.$v.endereco[name];
      return $dirty ? !$error : null;
    },
    onCidadeComplete(cidade) {
      this.endereco.codCidade = cidade ? cidade.id : null;
      this.endereco.cidade = cidade ? cidade.nome : null;
    },
    async verificaCep() {
      let validado = false;
      this.loading = true;
      // console.log(this.enderecoEdit.cep);
      // console.log(this.endereco.cep ? true : false);
      if (
        this.endereco.cep &&
        this.endereco.cep.length >= 9 &&
        this.endereco.cep !== this.enderecoEdit.cep
      ) {
        if (this.endereco.cep.length === 9) {
          await buscarCEP(this.endereco.cep)
            .then(data => {
              console.log(data);
              this.enderecoCep = data;
              validado = true;
              this.preencheEndereco();
            })
            .catch(err => {
              console.log("Erro ao buscar cep", err);
              validado = false;
            });
        } else {
          validado = false;
        }
      } else {
        validado = true;
      }
      this.loading = false;
      console.log("validado: ", validado);
      return validado;
    },
    async preencheEndereco() {
      this.$forceUpdate();
      await this.$nextTick();
      if (this.enderecoCep.state) {
        this.endereco.uf = this.enderecoCep.state;
      }

      if (this.enderecoCep.city) {
        this.endereco.cidade = this.enderecoCep.city;
      }

      if (this.enderecoCep.neighborhood) {
        this.endereco.bairro = this.enderecoCep.neighborhood;
      }
      if (this.enderecoCep.street) {
        this.endereco.logradouro = this.enderecoCep.street;
      }
      if (
        this.enderecoCep && this.enderecoCep.street && 
        this.tiposLogradouro.includes(this.enderecoCep.street.split(" ")[0])
      ) {
        this.endereco.tipoLogradouro = this.enderecoCep.street.split(" ")[0];
      }

      this.handleCodMunicipio();
    },
    async handleCodMunicipio() {
      if (this.endereco.uf && this.endereco.cidade) {
        this.$forceUpdate();
        this.$nextTick(async () => {
          this.loadingMunicipio = true;

          let municipios = await EnderecosLib.IBGE.getMunicipios(
            this.endereco.uf,
            this.endereco.cidade
          );
          console.log("encontrou os muncipios", municipios);
          if (Array.isArray(municipios)) {
            this.endereco.codCidade =
              municipios.find(
                m =>
                  m.nome
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase() ===
                  this.endereco.cidade
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
              ).codMunicipio || "";
          } else {
            if (municipios) {
              this.endereco.codCidade = municipios.codMunicipio;
            } else {
              this.codCidadeError = true;
            }
          }
          this.loadingMunicipio = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
