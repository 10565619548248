<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <b-table
            small
            stacked="lg"
            :fields="fields"
            hover
            striped
            :items="enderecos"
            selectable
            @row-selected="onRowSelected"
            select-mode="single"
            ref="enderecoTable"
          >
            <template #cell(Botoes)="row">
              <b-button
                size="sm"
                variant="warning"
                class="mr-1"
                @click="editar(row.item)"
                ><i class="fa fa-edit"></i> Editar</b-button
              >

              <b-button
                size="sm"
                variant="danger"
                class=""
                @click="$emit('apagarEndereco', enderecos.indexOf(row.item))"
                ><i class="fa fa-trash"></i> Excluir</b-button
              >
            </template>
          </b-table>
          <!-- <table class="table table-striped table-hover ">
            <thead>
              <tr>
                <th>CEP</th>
                <th>Cidade</th>
                <th>UF</th>
                <th>Logradouro</th>
                <th>N°</th>
                <th>Bairro</th>
                <th>Complemento</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="end in enderecos"
                :key="`endereco_${enderecos.indexOf(end)}`"
                class="text-nowrap"
              >
                <td class="d-block text-truncate">{{ end.cep }}</td>
                <td>{{ end.cidade }}</td>
                <td>{{ end.uf }}</td>
                <td>{{ end.logradouro }}</td>
                <td>{{ end.numero }}</td>
                <td>{{ end.bairro }}</td>
                <td>{{ end.complemento }}</td>
                <td>
                  <b-button
                    size="sm"
                    variant="primary"
                    class="mr-3"
                    @click="$emit('editarEndereco', enderecos.indexOf(end))"
                    ><i class="fa fa-edit"></i> Editar</b-button
                  >

                  <b-button
                    size="sm"
                    variant="secondary"
                    class=""
                    @click="$emit('apagarEndereco',enderecos.indexOf(end))"
                    ><i class="fa fa-trash"></i> Excluir</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import EnderecoForm from './EnderecoForm'
export default {
  props: {
    enderecos: {
      Type: Array,
      required: true,
    },
  },
  methods: {
    onRowSelected(row) {
      if (row.length > 0) {
        this.editar(row[0]);
        this.$refs.enderecoTable.clearSelected();
      }
    },
    editar(endereco) {
      this.$emit("editarEndereco", this.enderecos.indexOf(endereco));
    },
  },
  data() {
    return {
      fields: [
        { key: "cep", label: "CEP" },
        { Key: "cidade", label: "Cidade" },
        { key: "uf", label: "UF" },
        { key: "logradouro", label: "Logradouro" },
        { key: "numero", label: "Nª" },
        "complemento",
        { key: "Botoes", label: "", class: "text-right" },
      ],
    };
  },
};
</script>

<style>
</style>