<template>
  <div>
    <div>
      <b-form>
        <b-form-group>
          <div class="row">
            <div class="col-12 col-lg-4 text-nowrap">
              <label> Limite Qtd. Compras</label>
              <b-form-input
                type="number"
                v-model="convenio.limiteQtdCompras"
                class="form-control form-control-sm"
              />
            </div>

            <div class="col-12 col-lg-4 text-nowrap">
              <label> Limite Valor de Compras</label>
              <!-- <money
                v-model="convenio.limiteValorCompras"
                class="form-control form-control-sm"
                v-bind="money"
              /> -->
            </div>

            <div class="col-12 col-lg-4 text-nowrap">
              <label> Dia de Fechamento</label>
              <b-form-input
                v-model="convenio.diaFechamento"
                size="sm"
                v-mask="['##']"
              >
              </b-form-input>
            </div>
          </div>
        </b-form-group>
      </b-form>
      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Funcionários Disponiveis</label>
            <select-paciente
              withoutConvenio
              @paciente="addFuncionario"
            ></select-paciente>
            <!-- <v-select
              @input="addFuncionario"
              :options="funcionariosDisponiveis"
              label="razao_social"
              class="vs__sm"
            ></v-select> -->
            <small class="form-text text-muted"
              >*Os funcionários selecionados aqui serão adicionados a este
              convênio após salvar.
            </small>
          </b-form-group>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-12 col-sm-8 text-nowrap">
          <h5>Lista de Funcionários</h5>
        </div>

        <div class="col-12 col-sm-4">
          <Buscar
            :onFiltrar="filtrar"
            class="d-flex justify-content-end"
            :opened="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            :fields="campos"
            :filter="filtro"
            :items="funcionariosFiltrados"
            small
            outlined
            responsive="md"
            stacked="sm"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                Nenhum funcinário cadastrado neste convênio 🏦.
              </div>
            </template>
            <template #cell(telefones)>
              <!-- <div
                v-for="tel in row.item.telefones"
                :key="`telefone_${row.item.telefones.indexOf(tel)}_${tipo}`"
                class="d-block text-truncate"
              >
                {{ tel.telefone }}
              </div> -->
            </template>

            <template #cell(actions)="row">
              <b-btn
                variant="transparent"
                @click="removerFuncionario(row.item)"
              >
                <i class="fas fa-times text-primary"></i
              ></b-btn>
            </template>
          </b-table>

          <b-pagination> </b-pagination>
        </div>
      </div>

      <!-- <b-modal id="modal-funcionarios" body-class="pr-0 pl-0 pb-0" size="lg">
        <template #modal-title>
          <i class="fas fa-users"></i> Funcionários Disponiveis
        </template>
        <FuncionariosDisponiveis />
      </b-modal> -->
    </div>
  </div>
</template>

<script>
function _factoryConvenio() {
  return {
    diaFechamento: "",
    limiteValorCompras: 0,
    limiteQtdCompras: 0,
  };
}
import SelectPaciente from "../common/SelectPaciente.vue";
//import FuncionariosDisponiveis from "./FuncionariosDisponiveis.vue";
import Buscar from "@/components/common/Buscar.vue";
export default {
  components: {
    Buscar,
    SelectPaciente,
    // FuncionariosDisponiveis,
  },
  props: {
    convenio: {
      type: Object,
      default: _factoryConvenio(),
    },
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      filtro: "",
      funcionariosDisponiveis: [],
      campos: [
        {
          key: "id",
          label: "Código",
          class: "text-center",
        },
        {
          key: "razao_social",
          label: "Nome",
          class: "text-center",
        },
        {
          key: "cpf",
          label: "CPF",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    addFuncionario(funcionario) {
      if (!funcionario.id) return;

      if (
        this.convenio.funcionarios.some(
          (func) =>
            func.id == funcionario.id && funcionario._rowStatus != "deleted"
        )
      ) {
        this.showToast(
          "error",
          "Este funcionário já foi adicionado a este convênio"
        );
        return;
      }
      this.convenio.funcionarios.push({ ...funcionario, _rowStatus: "added" });
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
    removerFuncionario(_funcionario) {
      const i = this.convenio.funcionarios.findIndex(
        (func) => func.id == _funcionario.id
      );
      const funcionario = this.convenio.funcionarios[i];
      console.log(funcionario);
      if (!funcionario._rowStatus) {
        this.$set(funcionario, "_rowStatus", "deleted");
      } else {
        this.convenio.funcionarios.splice(i, 1);
      }
    },
  },
  mounted() {
    console.log("convenio", this.convenio);
  },
  computed: {
    funcionariosFiltrados() {
      return this.convenio.funcionarios.filter((func) =>
        func._rowStatus ? (func._rowStatus == "deleted" ? false : true) : true
      );
    },
  },
};
</script>

<style></style>
