var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[(
        _vm.entidade &&
        _vm.entidade.convenio_dependente_id &&
        _vm.entidade.convenio_dependente_id > 0
      )?_c('div',{staticClass:"col-12 mt-2 pl-2 font-weight-bold mb-3 text-center"},[_c('h4',[_c('i',{staticClass:"fa fa-user"}),_vm._v(" DEPENDENTE DE "+_vm._s(_vm.entidade.convenioTitular ? _vm.entidade.convenioTitular.razao_social : "")+" "),_c('div',[_c('b-btn',{attrs:{"variant":"danger"},on:{"click":_vm.desvincular}},[_c('b-icon-x-circle'),_vm._v(" DESVINCULAR")],1)],1)])]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('label',[_vm._v(" Empresa")]),_c('select-paciente',{attrs:{"disabled":_vm.entidade &&
          _vm.entidade.convenio_dependente_id &&
          _vm.entidade.convenio_dependente_id > 0,"showBotaoAdd":false,"showBotaoEdit":false,"selecionado":_vm.convenio_id,"tipo":['convenio']},on:{"paciente":_vm.handleInputConvenio}})],1),(
        !_vm.entidade ||
        !_vm.entidade.convenio_dependente_id ||
        _vm.entidade.convenio_dependente_id <= 0
      )?_c('div',{staticClass:"col-12 mt-2"},[_c('hr'),_vm._m(0),_c('b-table',{attrs:{"fields":[
          { key: 'razao_social', label: 'Nome' },
          { key: 'cpf', label: 'CPF' },
          { key: 'opc', label: '' },
        ],"items":_vm.deps},scopedSlots:_vm._u([{key:"cell(opc)",fn:function(row){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.del(row.item)}}},[_c('b-icon-trash-fill')],1)]}}],null,false,3600374912)}),_c('hr'),_c('h5',[_vm._v("Adicionar")]),_c('select-paciente',{ref:"selPAc",attrs:{"showBotaoEdit":false,"selecionado":_vm.dependente_id,"tipo":['paciente']},on:{"paciente":_vm.addDep,"new":_vm.onNew}})],1):_vm._e()]),_c('b-modal',{attrs:{"id":`modal-paciente-formCONVENIOPF`,"no-enforce-focus":"","hide-footer":"","size":"lg","modal-class":"modalMaiorFull","body-class":"modalNoPadding","hide-header":"","name":"modal-frm-cad-conv-fil"},on:{"close":_vm.closeModalPaciente}},[_c('PacienteFicha',{attrs:{"name":"modal-frm-cad-conv-fil","id":"modal-frm-cad-conv-fil","closeOnSave":"","paciente_id":0,"paciente":_vm.pacienteSelecionado},on:{"close":_vm.closeModalPaciente}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('i',{staticClass:"fa fa-users"}),_vm._v(" Dependentes")])
}]

export { render, staticRenderFns }