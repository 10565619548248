<template>
  <div class="container">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <entidade-form-skeleton></entidade-form-skeleton>
      </template>
      <b-form @submit.stop.prevent="salvar()">
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-3 d-flex flex-column align-content-start justify-content-start p-0 m-0 pr-sm-2"
          >
            <SendFiles
              style="margin: 0 auto; width: 100%"
              ref="file"
              :logo="Entidade.foto_entidade"
              @fileAddOrchanged="setNewFile($event)"
            />
          </div>

          <div class="col-12 col-sm-12 col-md-9 p-0 m-0">
            <div class="row">
              <div class="col-12 col-sm-8 col-lg-4">
                <b-form-group
                  class="text-start"
                  label="Tipo de Pessoa"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="Entidade.tipo"
                    :options="option_tipo"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-4 col-lg-3">
                <b-form-group class="text-center">
                  <label>Situação</label>

                  <b-form-checkbox
                    v-model="Entidade.status"
                    :value="1"
                    :unchecked-value="0"
                    name="check-button"
                    switch
                    class="mt-2 form-control text-center"
                  >
                    <span v-if="!Entidade.status || Entidade.status == 0">Inativo</span>
                    <span v-if="Entidade.status && Entidade.status == 1">Ativo</span>
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-12 col-lg-5 p-3 d-none d-lg-block">
                <div class="card" v-if="Entidade.usuario && !user.isMedico">
                  <div class="card-header bg-white"><b-icon-person /> Usuário</div>
                  <div class="card-body">
                    <!-- <div>
                      <label> Nome: </label>
                      {{ Entidade.usuario.nome }}
                    </div> -->

                    <div>
                      <label> Usuário: </label>
                      {{ Entidade.usuario.usuario }}<br />
                      <small class="text-muted">
                        * A primeira senha são os 6 primeiros digitos do CPF/CNPJ
                      </small>
                    </div>
                    <!-- <div class="d-flex">
                      <label class="mr-2"> Situação: </label>
                      <b-form-checkbox
                        :disabled="true"
                        :checked="!!Entidade.usuario.situacao"
                        switch
                        >{{
                          Entidade.usuario.situacao ? "Ativo" : "inativo"
                        }}</b-form-checkbox
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-12 col-sm-6"
                v-if="exibeCampo('razao_social')"
                key="razao_social"
              >
                <b-form-group>
                  <label>{{ Entidade.tipo == "PJ" ? "Razão Social" : "Nome" }}</label>
                  <b-form-input
                    :placeholder="Entidade.tipo == 'PJ' ? 'Razão Social' : 'Nome'"
                    v-model="Entidade.razao_social"
                    :state="validateState('razao_social')"
                  />
                  <b-form-invalid-feedback>
                    Informe um
                    {{ Entidade.tipo == "PJ" ? "Razão Social" : "Nome" }}
                    válido</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- <div
                class="col-12 col-sm-6"
                v-if="exibeCampo('ie')"
                key="ie"
              ></div> -->
              <div class="col-12 col-sm-6" key="cpf" v-if="exibeCampo('cpf')">
                <b-form-group>
                  <label>{{ Entidade.tipo == "PF" ? "CPF" : "CNPJ" }}</label>

                  <b-form-input
                    :placeholder="Entidade.tipo == 'PJ' ? 'CNPJ' : 'CPF'"
                    v-model="Entidade.cpf"
                    :state="validateState('cpf')"
                    v-mask="['##.###.###/####-##', '###.###.###-##']"
                    @blur="valCPF"
                    :disabled="loadingChangeCPF"
                  />
                  <b-form-invalid-feedback>
                    Informe um
                    {{ Entidade.tipo == "PF" ? "CPF" : "CNPJ" }}
                    válido</b-form-invalid-feedback
                  >
                  <span v-if="loadingChangeCPF"
                    ><b-spinner size="sm" small /> validando...</span
                  >
                </b-form-group>
              </div>

              <div
                class="col-12 col-sm-6"
                v-if="exibeCampo('fantasia') && Entidade.tipo == 'PJ'"
                key="fantasia"
              >
                <b-form-group>
                  <label>Nome Fantasia</label>
                  <b-form-input
                    placeholder="Fantasia"
                    v-model="Entidade.fantasia"
                    :state="validateState('fantasia')"
                  />
                  <b-form-invalid-feedback>
                    Informe um nome fantasia válido</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-group>
                  <label>{{ Entidade.tipo == "PJ" ? "Inscrição Estadual" : "RG" }}</label>
                  <b-form-input
                    :placeholder="Entidade.tipo == 'PJ' ? 'Inscrição Estadual' : 'RG'"
                    :state="validateState('ie')"
                    v-model="Entidade.ie"
                  />
                  <b-form-invalid-feedback>
                    Informe
                    {{ Entidade.tipo == "PJ" ? "uma Inscrição válida" : "um RG válido" }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="col-12 col-sm-6" v-if="exibeCampo('crm')" key="crm">
                <b-form-group>
                  <label>CRM</label>
                  <b-form-tags
                    tag-variant="primary"
                    placeholder="CRM"
                    :state="validateState('crm')"
                    v-model="Entidade.crm"
                  />
                  <b-form-invalid-feedback>
                    Informe um CRM válido</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>

              <div
                key="dt_nascimento"
                class="col-12 col-sm-6"
                v-if="exibeCampo('dt_nascimento') && Entidade.tipo == 'PF'"
              >
                <b-form-group>
                  <label
                    >Data de Nascimento
                    <span v-if="idade">({{ idade }} anos)</span></label
                  >

                  <b-form-input
                    type="date"
                    v-model="Entidade.dt_nascimento"
                    :state="validateState('dt_nascimento')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >Infrome a data de nascimento</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>

              <div class="col-12" key="email" v-if="exibeCampo('email')">
                <b-form-group>
                  <label>E-mail</label>
                  <b-form-input
                    placeholder="E-mail"
                    :state="validateState('email')"
                    v-model="Entidade.email"
                  />

                  <b-form-invalid-feedback>
                    Informe um e-mail válido</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-form>

 
      <b-tabs
        :pills="!isMobile"
        :vertical="!isMobile"
        small
        fill
        class="border p-0 py-md-3 shadow rounded"
        content-class="p-3"
      >
        <b-tab title="Telefones">
          <template #title> <i class="fa fa-phone" /> Telefone(s) </template>

          <Telefones :telefones="Entidade.telefones" :title="''" />
        </b-tab>
        <b-tab title="Endereços">
          <template #title> <i class="fa fa-map" /> Endereço(s) </template>
          <Enderecos
            showTipoLogradouro
            :enderecos="Entidade.enderecos"
            :consultorio="!!Entidade.medico"
          />
        </b-tab>

        <b-tab title="Anexo">
          <template #title> <i class="fas fa-paperclip"></i>Anexos </template>

          <Anexos tipo="Entidades" :anexos="Entidade.anexos" ref="anexoComponent" />
        </b-tab>

        <b-tab title="Observações">
          <template #title> <i class="fas fa-sticky-note"></i> Observações</template>
          <div class="row">
            <div class="col-6">
              <b-form-group>
                <b-form-checkbox
                  :value="1"
                  :unchecked-value="0"
                  v-model="Entidade.exibe_obs"
                >
                  Exibir em telas</b-form-checkbox
                >
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group>
                <label> Observações / Comentários </label>
                <b-form-textarea v-model="Entidade.obs" rows="5"></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </b-tab>

        <b-tab title="Especialidades" v-if="exibeCampo('especialidades')">
          <template #title> <i class="fa fa-stethoscope" /> Especialidades </template>
          <EspecialidadesListagem
            :refEspecialidade="Entidade.configuracoes.especialidades"
            @especialidadeChange="atualizaEspecialidade($event)"
          />
        </b-tab>

        <b-tab title="Contas Bancárias" v-if="exibeCampo('contasBancarias')">
          <template #title> <i class="fa fa-university" /> Contas Bancárias </template>

          <ContasBancarias
            :contasBancarias="Entidade.configuracoes.contasBancarias"
            :entidade="Entidade"
            @salvou="salvarContaBancaria($event)"
            @excluiu="excluirContabancaria($event)"
          />
        </b-tab>

        <b-tab title="Convênio" v-if="exibeCampo('convenio')">
          <template #title> <i class="fas fa-address-card"></i> Convenio </template>

          <div class="row mt-3">
            <div class="col">
              <ConvenioPF
                :convenio_id="Entidade.convenio_id"
                @convenio="onConvenio"
                :dependentes="Entidade.configuracoes.convenio_dependentes"
                @addDep="addConvenioDEP"
                @delDep="delConvenioDEP"
                :entidade="Entidade"
                name="ConvenioPFF"
              />
            </div>
          </div>
        </b-tab>

        <b-tab title="Pacientes Vinculados" v-if="exibeCampo('pacientesVinculados')">
          <template #title>
            <i class="fas fa-address-card"></i> Pacientes Vinculados
          </template>

          <div class="row mt-3">
            <div class="col">
              <ConvenioPJ :convenio="Entidade.configuracoes.convenio" />
            </div>
          </div>
        </b-tab>

        <b-tab v-if="exibeCampo('produtoServico')">
          <template #title>
            <i class="fas fa-hand-holding-medical"></i> Produto/Serviço
          </template>

          <div class="row">
            <div class="col">
              <Exames
                :examesProp="Entidade.exames"
                @valueChanged="Entidade.exames = $event"
                :aceitaFidelizacao="!!Entidade.aceita_fidelizacao"
                :entidade_id="Entidade.id"
              />
            </div>
          </div>
        </b-tab>
        <b-tab title="Configurações">
          <template #title> <i class="fa fa-cogs" /> Configurações </template>

          <div class="">
            <collapse nomeComponente="Tipo de Cadastro" :startVisible="true">
              <b-check
                switch
                v-model="Entidade.medico"
                :unchecked-value="0"
                :value="1"
                inline
                >Médico</b-check
              >

              <b-check
                switch
                v-model="Entidade.laboratorio"
                :unchecked-value="0"
                :value="1"
                inline
                >Laboratório</b-check
              >
              <b-check
                switch
                v-model="Entidade.convenio"
                :unchecked-value="0"
                :value="1"
                inline
                >Convenio</b-check
              >
              <b-check
                switch
                v-model="Entidade.fornecedor"
                :unchecked-value="0"
                :value="1"
                inline
                >Fornecedor</b-check
              >
              <b-check
                switch
                v-model="Entidade.paciente"
                :unchecked-value="0"
                :value="1"
                inline
                >Paciente</b-check
              >
              <b-check
                switch
                v-model="Entidade.funcionario"
                :unchecked-value="0"
                :value="1"
                inline
                >Funcionário</b-check
              >
            </collapse>

            <b-form-checkbox
              v-model="Entidade.aceita_fidelizacao"
              :value="1"
              :unchecked-value="0"
              switch
              class="mt-4"
              >Aceita Fidelização</b-form-checkbox
            >
          </div>
        </b-tab>
      </b-tabs>

      <div class="text-right" v-if="saveButton">
        <hr />
        <div class="float-left text-danger" v-if="hasChange">
          <b-icon-info-circle /> Alterações realizadas! Não se esqueça de salvar!
        </div>

        <div class="d-flex justify-content-end">
          <b-btn-exibelog
            variant="info"
            v-if="!!Entidade.id"
            @click="openLogs"
            class="mr-3"
            :namedKeys="namedKeys"
            :id_externo="Entidade.id"
            tabela="entidades"
          />
          <b-btn
            variant="primary"
            class="mr-3"
            @click="salvar"
            :disabled="salvando || !hasChange"
          >
            <div v-if="!salvando"><i class="fa fa-save" /> Salvar</div>
            <div v-else><b-spinner small /> salvando...</div>
          </b-btn>
          <b-btn :disabled="salvando" @click="closeModal">
            <i class="fas fa-ban"></i> Cancelar
          </b-btn>
        </div>

        <!-- <button class="btn btn-secondary"><i class="fa fa-ban" /> Cancelar</button> -->
      </div>
    </b-skeleton-wrapper>

    <b-modal id="modal-cpfIsUsed" size="lg" hide-footer>
      <template #modal-title>
        <div>
          CPF <b>({{ Entidade.cpf }})</b> Usado em vários cadastros, selecone o cadastro
          correto
        </div>
      </template>
      <div>
        <div class="text-center p-3" v-if="loadingChangeCPF">
          <h2>
            <b-spinner size="lg" />
            atualizando...
          </h2>
        </div>
        <table class="table table-striped" v-if="!loadingChangeCPF">
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>CPF</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-weight: bold">
              <td>{{ Entidade.id }}</td>
              <td>{{ Entidade.razao_social }}</td>
              <td>{{ Entidade.cpf }}</td>
              <td class="text-center">
                <b>ESTE CADASTRO</b> <br />
                <b-btn variant="success" @click="selectCPF(Entidade.id)"
                  >Selecionar</b-btn
                >
              </td>
            </tr>
            <tr
              v-for="n in listCPFDuplicated"
              :key="`lwn_cpf_dup_${n.id}_${Math.random(2323232)}`"
            >
              <td>{{ n.id }}</td>
              <td>{{ n.razao_social }}</td>
              <td>{{ n.cpf }}</td>
              <td class="text-center" @click="selectCPF(n.id)">
                <b-btn variant="success">Selecionar</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EntidadesLib from "../../libs/EntidadesLib";
import moment from "moment";
import FilesLib from "../../libs/FilesLib.js";
import Validadores from "../../helpers/Validadores";
import SendFiles from "../common/SendFiles.vue";
import Telefones from "../Telefones/Telefones.vue";
import Enderecos from "../Enderecos/Endereco.vue";
import Anexos from "../Anexos/Anexos.vue";
import ConvenioPJ from "../Convenio/ConvenioPJ.vue";
import ConvenioPF from "../Convenio/ConvenioPF.vue";
import Collapse from "../common/Collapse.vue";
// import HandlerCPFDuplicated from "../common/HandlerCPFDuplicated.vue";

function required(value) {
  // console.log("value", value);
  return !Validadores.string.IsNullOrEmpty(value);
}

function date(value) {
  return required(value) && value.indexOf("Data inválida") === -1;
}

function minLength(value, minLength) {
  // console.log(value);
  return value ? value.length >= minLength : false;
}

function cpf(value) {
  console.log("validando cpf", value);
  return Validadores.string.validarCPF(value);
}

async function cpfOrCnpjUsed(value) {
  // console.log("VALUE: ", value);
  if (value && value.length >= 13) return await Validadores.string.CpfOrCnpjUsed(value);
}

function cnpj(value) {
  return Validadores.string.validarCNPJ(value);
}

export default {
  name: "EntidadeFormNew",
  components: {
    SendFiles,
    Telefones,
    Enderecos,
    Anexos,
    EntidadeFormSkeleton: () => import("./Skeleton/EntidadeFormSkeleton.vue.vue"),
    EspecialidadesListagem: () => import("../Especialiades/EspecialidadesListagem.vue"),
    ContasBancarias: () => import("../ContasBancarias/ContasBancarias.vue"),
    Exames: () => import("../Exames/Listagem/Exames.vue"),
    ConvenioPF,
    // HandlerCPFDuplicated,
    ConvenioPJ,
    Collapse,
  },
  props: {
    entidadeId: [Number, String],
    entidadeObj: {
      type: [Object, null],
      default: null,
    },
    tipo: String,
    saveButton: { type: Boolean, default: true },
    autoSave: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    Entidade: {
      deep: true,
      handler() {
        //console.log("oiii", this.loading, this.hasChange);
        this.idade = Math.floor(
          moment(new Date()).diff(
            moment(this.Entidade.dt_nascimento, "YYYY-MM-DD"),
            "years",
            true
          )
        );
        // console.log(this.idade)
        if (!this.loading) {
          this.hasChange = true;
          if (this.autoSave) {
            this.salvar();
          }
        }
      },
    },
    "entidadeObj.id"(newV, oldV) {
      if (newV !== oldV) {
        this.carregaEntidade();
      }
    },
    entidadeId(newV, oldV) {
      if (newV !== oldV) {
        this.carregaEntidade();
      }
    },
  },
  computed: {},

  data() {
    return {
      idade: null,
      hasChange: false,
      Entidade: {
        razao_social: "",
        fantasia: "",
        crm: [],
        ie: "",
        cpf: "",
        email: "",
        status: 1,
        tipo: "PF",
        dt_nascimento: moment().format("YYYY-MM-DD"),
        telefones: [],
        enderecos: [],
        aceita_fidelizacao: 1,
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
            funcionarios: [],
          },
          convenio_dependentes: [],
        },
        exames: [],
        anexos: [],
        empresa_id: null,
        user_id: null,
        convenio: this.tipo == "convenio" ? 1 : 0,
        fornecedor: this.tipo == "fornecedor" ? 1 : 0,
        laboratorio: this.tipo == "laboratorio" ? 1 : 0,
        medico: this.tipo == "medico" ? 1 : 0,
        paciente: this.tipo == "paciente" ? 1 : 0,
        funcionario: this.tipo == "funcionario" ? 1 : 0,
        convenio_id: "",
        obs: "",
        exibe_obs: 0,
      },
      listCPFDuplicated: [],
      hasErrors: [],
      loading: false,
      option_tipo: [
        { value: "PF", text: "Pessoa Física" },
        { value: "PJ", text: "Pessoa Jurídica" },
      ],
      salvando: false,
      namedKeys: {
        id: "Código",
        anexos: "Anexos",
        exames: "Exames",
        dt_nascimento: "Data de Nascimento",
      },
      loadingChangeCPF: false,
      errorCPFIsSaving: false,
      isMobile: false,
    };
  },
  methods: {
    verifyIsMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    async selectCPF(id) {
      this.$swal({
        title: "Você deseja continuar?",
        text:
          "Após alteração não será possível reverter a alteração. Todos os demais cadastros serão desvinculados do CPF informado!",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (r) => {
        this.loadingChangeCPF = true;
        try {
          if (r.isConfirmed) {
            this.loadingChangeCPF = true;
            console.log("unificando", this.Entidade.cpf, id);
            let res = await EntidadesLib.unificarCPF(this.Entidade.cpf, id);
            console.log("resUnifica", res);
            this.loadingChangeCPF = false;

            this.$swal("Concluido", "", "success");
            if (id && !this.errorCPFIsSaving) {
              this.entidadeId = id;
              await this.carregaEntidade();
              this.$emit("carregar", id);
            } else {
              this.salvar();
            }
            this.$bvModal.hide("modal-cpfIsUsed");
          }
        } catch (err) {
          console.log("ERRO", err);
          this.loadingChangeCPF = false;
          this.$swal("ERRO", "Erro ao desvincular o CPF dos demais cadastros!", "error");
        }
        this.loadingChangeCPF = false;
      });

      this.loadingChangeCPF = false;
    },
    async valCPF() {
      this.loadingChangeCPF = true;
      const cpfOrCnpjIsUsed = await cpfOrCnpjUsed(this.Entidade.cpf);
      console.log("valid", this.Entidade.cpf, this.Entidade.id, cpfOrCnpjIsUsed.list);
      if (cpfOrCnpjIsUsed && cpfOrCnpjIsUsed.isDuplicated) {
        this.listCPFDuplicated = cpfOrCnpjIsUsed.list.filter(
          (x) => !this.Entidade.id || x.id !== this.Entidade.id
        );
        console.log("=---->", this.listCPFDuplicated);

        // this.addOrUpdateError({ campo: "cpf", state: false, label: false });
        if (this.listCPFDuplicated && this.listCPFDuplicated.length > 0) {
          this.loadingChangeCPF = false;

          this.$bvModal.show("modal-cpfIsUsed");

          return;
        }
      } else {
        this.addOrUpdateError({ campo: "cpf", state: true, label: false });
      }
      this.loadingChangeCPF = false;
    },
    delConvenioDEP(dep) {
      this.hasChange = true;
      this.Entidade.configuracoes.convenio_dependentes = this.Entidade.configuracoes.convenio_dependentes.filter(
        (x) => x.id != dep.id
      );
      this.$forceUpdate();
    },
    addConvenioDEP(dep) {
      this.hasChange = true;
      this.Entidade.configuracoes.convenio_dependentes.push({
        id: dep.id,
        razao_social: dep.razao_social,
        cpf: dep.cpf,
      });

      this.$forceUpdate();
    },
    async carregaEntidade() {
      const id = this.entidadeId || this.entidadeObj?.id;
      if (id && this.tipo) {
        await this.$nextTick();
        this.$forceUpdate();
        this.loading = true;
        console.log("tipo", this.tipo);
        await EntidadesLib.get(this.tipo, "", id)
          .then((res) => {
            this.Entidade = Object.assign(
              {},
              { ...Object.assign(this.Entidade, res.entidades[0]) }
            );
            if (!this.Entidade.configuracoes) {
              this.Entidade.configuracoes = {
                especialidades: [],
                contasBancarias: [],
                convenio: {
                  limiteQtdCompras: null,
                  limiteValorCompras: null,
                  diaFechamento: null,
                  funcionarios: [],
                },
                convenio_dependentes: [],
              };
            }
            if (!this.Entidade.configuracoes.convenio_dependentes) {
              this.Entidade.configuracoes.convenio_dependentes = [];
            }
          })
          .finally(() => {
            this.Entidade.dt_nascimento = moment(this.Entidade.dt_nascimento).format(
              "YYYY-MM-DD"
            );
            this.loading = false;
          });

        this.hasChange = false;
      }
    },
    async onHandlerCPF(type, data = {}) {
      console.log(data);
      if (type === "success") {
        await this.carregaEntidade();

        this.$bvModal.hide("modal-cpfIsUsed");
        this.$swal({
          title: "Deseja salvar?",
          text: "Você unificou o CPF com sucesso, deseja tentar salvar novamente?",
          icon: "success",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Salvar",
          cancelButtonText: "Nâo, ainda preciso fazer algumas alterações...",
        }).then(async (x) => {
          if (x.isConfirmed) {
            await this.salvar();
          }
        });
      } else if (type === "error") {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: `Ocorreu algum erro ao unificar CPF's.`,
        });
      }
    },
    onConvenio(convenio) {
      this.Entidade.convenio_id = convenio ? convenio?.id : null;
      this.hasChange = true;
    },
    openLogs() {
      this.$bvModal.show("modal-logs");
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError({ campo, state, label }) {
      // console.log(campo, state, label);
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state, label });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();
      await this.$nextTick();

      let validations = [
        {
          campo: "razao_social",
          rule: [required],
          label:
            this.Entidade.tipo == "PF" ? "Nome está vazio" : "Razao Social está vazia",
        },
        // {
        //   campo: "ie",
        //   rule: [required],
        //   label: this.Entidade.tipo == "PF" ? "RG" : "IE",
        // },
        {
          campo: "cpf",
          rule: [required, this.Entidade.tipo == "PF" ? cpf : cnpj],
          label: this.Entidade.tipo == "PF" ? "CPF inválido" : "CNPJ inválido",
        },
        {
          campo: "dt_nascimento",
          rule: [
            this.Entidade.tipo == "PF" && this.Entidade.paciente === 1 ? date : () => {},
          ],
          label: "Data de Nascimento inválida",
        },
        {
          campo: "telefones",
          rule: [minLength],
          label: "Adicione ao menos um telefone",
        },
        {
          campo: "enderecos",
          rule: [required],
          label: "Adicione ao menos um endereço",
        },
        //{ campo: "configuracoes.especialidades", rule: [required], label: "Adicione ao menos uma especialidade" },
        { campo: "crm", rule: [minLength], label: "CRM está vazio" },
      ];

      validations.map((valid) => {
        if (this.exibeCampo(valid.campo)) {
          console.log(valid);
          this.addOrUpdateError({
            campo: valid.campo,
            state: !valid.rule
              .map((v) => v(this.Entidade[valid.campo], 1))
              .some((e) => e == false),
            label: valid.label,
          });
        }
      });

      if (this.exibeCampo("especialidades")) {
        this.addOrUpdateError({
          campo: "especialidades",
          state: this.Entidade.configuracoes.especialidades.length > 0,
          label: "Adicione ao menos uma especialidade",
        });
      }

      // if (!this.Entidade.id) {
      const cpfOrCnpjIsUsed = await cpfOrCnpjUsed(this.Entidade.cpf);
      if (cpfOrCnpjIsUsed && cpfOrCnpjIsUsed.isDuplicated) {
        this.listCPFDuplicated = cpfOrCnpjIsUsed.list.filter(
          (x) => !this.Entidade.id || x.id !== this.Entidade.id
        );

        this.errorCPFIsSaving = true;
        if (this.listCPFDuplicated.length > 0) {
          this.addOrUpdateError({ campo: "cpf", state: false, label: false });
          this.$bvModal.show("modal-cpfIsUsed");
          return;
        }
      }
      //}
      console.log("HAS ERRORS", this.hasErrors);
      let errors = this.hasErrors.filter((x) => x.state == false);
      console.log(errors);

      if (errors.length > 0) {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errors
            .map((e) => `${e.label}`)
            .reduce((it, vl) => {
              it += vl + " <hr />";
              return it;
            }, "")} `,
        });
        return false;
      }
      return true;
    },
    async salvar() {
      if (await this.validar()) {
        try {
          await this.salvarFilesAnexo();
          this.salvando = true;

          const result = await EntidadesLib.store(this.Entidade);

          if (result && result.success === true) {
            if (!this.Entidade.id || this.Entidade.id <= 0) {
              let resultBsc = await EntidadesLib.buscaPorCampo({
                id: result.data[0],
              });
              if (resultBsc && resultBsc.length > 0) {
                this.$emit("onSave", resultBsc[0]);
              }
            } else {
              this.$emit("onSave", this.Entidade);
            }

            this.$swal
              .fire({
                toast: true,
                title: "Concluído",
                text: "Cadastro salvo com sucesso!",
                icon: "success",
                position: "top-end",
                timer: 1500,
                showConfirmButton: false,
                timerProgressBar: true,
              })
              .then(() => {
                return true;
              });

            this.salvando = false;
            this.hasChange = false;

            return true;
          } else {
            this.showToast("error", result.msg, 2500);
          }
        } catch (error) {
          this.$swal.fire({
            toast: true,
            title: "Ops! Erro ao salvar!",
            icon: "error",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
          return error;
        } finally {
          this.salvando = false;
        }
        return false;
      }
    },
    async salvarFilesAnexo() {
      if (
        this.Entidade.anexos.length > 0 &&
        this.Entidade.anexos.some((anex) => anex._rowStatus)
      ) {
        console.log("adicionando anexos...");
        await Promise.all(
          this.Entidade.anexos.map(async (anexo, i) => {
            console.log(`anexo ${i + 1} de ${this.Entidade.anexos.length}`);
            if (Array.isArray(anexo.arquivos) && anexo.arquivos.length > 0) {
              for (const arquivo of anexo.arquivos) {
                console.log(`arquivo ${anexo.arquivos.indexOf(arquivo) + 1}`, arquivo);
                try {
                  arquivo.url = (await FilesLib.uploadFile(arquivo.file)).data.file;

                  delete arquivo.file;
                  console.log(`arquivo ${anexo.arquivos.indexOf(arquivo) + 1}`, arquivo);
                } catch (error) {
                  console.log(error);
                  this.showToast(
                    "error",
                    `"Ocorreu algum problema ao salvar o arquivo ${
                      anexo.arquivos.indexOf(arquivo) + 1
                    } do anexo ${anexo.titulo}, tente novamente mais tarde."`
                  );
                }
              }
            }
          })
        );
      }
    },
    async closeModal() {
      if (this.hasChange) {
        this.$swal({
          title: "Salvar alterações?",
          text: "Você realizou alterações no cadastro. Deseja salvar antes de sair?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Salvar Alterações",
          cancelButtonText: "Descartar",
        }).then(async (x) => {
          if (x.isConfirmed) {
            await this.salvar();
          }
          this.$emit("close", this.hasChange);
        });
      } else {
        this.$emit("close", this.hasChange);
      }
    },
    setNewFile(file) {
      this.$forceUpdate();
      this.Entidade.foto_entidade = file;
    },
    async atualizaEspecialidade(newValue) {
      this.$forceUpdate();
      await this.$nextTick();
      console.log("atualizou especialidade", newValue);

      this.Entidade.configuracoes.especialidades = newValue;
    },
    async salvarContaBancaria(contaBancaria) {
      // console.log("contabancaria no form", contaBancaria);
      if (!this.Entidade) {
        this.Entidade = {};
      }
      if (!this.Entidade.configuracoes) {
        this.Entidade.configuracoes = {};
      }
      if (!this.Entidade.configuracoes.contasBancarias) {
        this.Entidade.configuracoes.contasBancarias = [];
      }

      await this.$nextTick();

      if (typeof contaBancaria.index == "number") {
        this.$set(
          this.Entidade.configuracoes.contasBancarias,
          contaBancaria.index,
          contaBancaria
        );
      } else {
        this.Entidade.configuracoes.contasBancarias.push(contaBancaria);
      }
      this.hasChange = true;
      this.$forceUpdate();
    },
    excluirContabancaria(indexContaBancaria) {
      // console.log('index p  excluir',indexContaBancaria);

      this.Entidade.configuracoes.contasBancarias.splice(indexContaBancaria, 1);
      // console.log(this.Entidade.configuracoes.contasBancarias);
    },
    exibeCampo(name) {
      let campo = Campos[name];

      return (
        (campo
          ? campo.includes("*")
            ? true
            : campo.some((cp) => this.Entidade[cp] == 1)
          : false) &&
        (campo.includes(this.tipo) || campo.includes("*"))
      );
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.entidadeId != "" || this.entidadeObj?.id) {
        this.carregaEntidade();
      } else if (this.entidadeObj) {
        this.Entidade = Object.assign(this.Entidade, this.entidadeObj);
      }
      this.hasChange = false;
    });
    this.verifyIsMobile();
    window.removeEventListener("resize", this.verifyIsMobile);
    window.addEventListener("resize", this.verifyIsMobile);
  },
};

const Campos = {
  razao_social: ["*"],
  ie: ["*"],
  cpf: ["*"],
  crm: ["medico"],
  telefones: ["*"],
  fantasia: ["*"],
  email: ["*"],
  contasBancarias: ["medico", "funcionario", "fornecedor", "laboratorio", "funcionario"],
  convenio: ["paciente", "funcionario"],
  pacientesVinculados: ["convenio"],
  anexos: ["*"],
  dt_nascimento: ["*"],
  produtoServico: ["laboratorio", "medico", "fornecedor"],
  especialidades: ["medico"],
};
</script>

<style scoped></style>
