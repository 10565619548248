<template>
  <div>
    <b-modal
      id="modal-endereco"
      header-bg-variant="transparent"
      bodyTextVariant=""
      fades
      body-bg-variant=""
      title=""
      size=""
      hide-footer
    >
      <template #modal-header>
        <h3><i class="fa fa-building"></i> Adicionar Endereço</h3>
      </template>
      <EnderecoForm
      :showTipoLogradouro="showTipoLogradouro"
        ref="EnderecoForm"
        @salvar="addOrUpdateEndereco($event)"
        :enderecoEdit="enderecoEdit"
        :consultorio="consultorio"
      />
    </b-modal>
    <!-- <Collapse :nomeComponente="'Endereços'" :iconeClasse="'fas fa-map-marked-alt text-danger'"> -->
    <EnderecoTable
      :enderecos="enderecos"
      @editarEndereco="editarEndereco($event)"
      @apagarEndereco="apagarEndereco($event)"
         :consultorio="consultorio"
    />

    <div class="row">
      <div class="col-12">
        <b-button size="sm" variant="transparent" @click="novoEndereco()">
          <i class="fa fa-plus"></i> Adicionar
        </b-button>
      </div>
    </div>
    <!-- </Collapse> -->
  </div>
</template>

<script>
import EnderecoForm from "../../components/Enderecos/EnderecoForm.vue";
import EnderecoTable from "../../components/Enderecos/EnderecoTable.vue";
// import Collapse from "../common/Collapse";

export default {
  components: {
    EnderecoForm,
    EnderecoTable,
    // Collapse,
  },
  props: {
    enderecos: Array,
    consultorio: {type: Boolean, deafult: false},
    showTipoLogradouro: {type: Boolean, deafult: false},
  },
  data() {
    return {
      enderecoEdit: {},
    };
  },
  methods: {
    novoEndereco() {
      this.enderecoEdit = {};
      this.$bvModal.show("modal-endereco");
    },
    async editarEndereco(indexEndereco) {
      this.enderecoEdit = Object.assign(
        {},
        { ...this.enderecos[indexEndereco], index: indexEndereco }
      );
      await this.$nextTick();

      this.$bvModal.show("modal-endereco");
    },
    async addOrUpdateEndereco(endereco) {
      await this.$nextTick();
      console.log(endereco);
      if (endereco.index != null) {
        this.$set(this.enderecos, endereco.index, endereco);
        this.enderecoEdit = {};
      } else {
        this.enderecos.push(endereco);
      }

      this.$bvModal.hide("modal-endereco");
    },
    apagarEndereco(index) {
      this.enderecos.splice(index, 1);
    },
  },
};
</script>

<style></style>
