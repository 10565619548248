<template>
  <div class="container-fluid">
    <barra-crud
      :onFiltrar="filtrar"
      :botaoNovo="true"
      :onNovo="onNovo"
      ref="barraCrud"
      :key="tipo"
      :minLength="3"
      filterAlphabet
      :filterAlphabetDisable="loading"
      :char="filtros.alfabeto"
      @charClicked="filtros.alfabeto = $event"
    />
    <b-tabs>
      <b-tab @click="filtros.status = 1">
        <template #title> {{ nomeEntidade }} Ativos </template>
      </b-tab>
      <b-tab @click="filtros.status = 0"
        ><template #title> {{ nomeEntidade }} Inativos</template></b-tab
      >
    </b-tabs>
    <entidades-table
      :filtros="filtros"
      ref="entidadeTable"
      :onEditar="onEditar"
      :tipo="tipo"
      @novo="onNovo"
      @resetFilters="resetFilters"
      @loading="loading = $event"
    />

    <b-modal
      id="modal-Entidades"
      @hide="() => {}"
      no-fade
      content-class="modalDireito"
      hide-footer
    >
      <template #modal-title> Adicionar/Editar {{ nomeEntidade }} </template>
      <entidades-form
        @onSave="onSalvar"
        :entidadeId="entidadeEditId"
        :tipo="tipo"
        @close="closeModal($event)"
        ref="entidadeForm"
      />
      <!-- <template #modal-footer>
        <b-button
          variant="primary"
          @click="salvar"
          :disabled="salvando"
          type="submit"
        >
          <span v-if="!salvando"> <i class="fa fa-save"></i> Salvar </span>
          <span v-if="salvando">
            <b-spinner type="grow" small />
            Salvando
          </span>
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('modal-Entidades')"
          ><i class="fa fa-times"></i> Cancelar</b-button
        >
      </template> -->
    </b-modal>
  </div>
</template>

<script>
import EntidadesTable from "../../components/Entidades/EntidadesTable.vue";
import EntidadesForm from "../../components/Entidades/EntidadesFormNew.vue";
import BarraCrud from "../../components/common/BarraCrud.vue";
export default {
  props: {
    tipo: String,
  },
  components: {
    BarraCrud,
    EntidadesTable,
    EntidadesForm,
  },
  mounted() {},
  data() {
    return {
      salvando: false,
      filtro: "",
      entidadeEditId: "",
      hasChanged: false,
      descartado: false,
      filtros: {
        alfabeto: "",
        geral: "",
        status: 1
      },
      loading: false,
      //   Entidade: {
      //     id: "",
      //     razao_social: "",
      //     fantasia: "",
      //     crm: "",
      //     ie: "",
      //     cpf: "",
      //     email: "",
      //     status: 1,
      //     tipo_pessoa: "PF",
      //     telefones: [],
      //     enderecos: [],
      //     configuracoes: {
      //       especialidades: [],
      //       contasBancarias: [],
      //       convenio: {
      //         limiteQtdCompras: null,
      //         limiteValorCompras: null,
      //         diaFechamento: null,
      //       },
      //     },
      //     exames: [],
      //     empresa_id: null,
      //     user_id: null,
      //     convenio: 0,
      //     fornecedor: 0,
      //     laboratorio: 0,
      //     medico: 0,
      //   },
    };
  },
  methods: {
    filtrar(filtro) {
      console.log(filtro);
      this.$forceUpdate();
      this.filtros.geral = filtro;
    },
    resetFilters() {
      this.filtros.alfabeto = "";
      this.filtros.geral = "";
      this.filtros.status = 1
    },
    async onNovo() {
      this.entidadeEditId = "";
      this.$bvModal.show("modal-Entidades");
    },
    charClicked(evt) {
      if (!evt) return;
      evt.preventDefault();
      this.filtros.alfabeto = evt.target.innerText;
    },
    // async onClose(event) {
    //   console.log("event", event);
    //   console.log(this.$refs.entidadeForm.hasChange);
    //   // event.preventDefault();
    //   if (this.$refs.entidadeForm.hasChange) {
    //     event.preventDefault();
    //     await this.$swal({
    //       title: "Salvar alterações?",
    //       text:
    //         "Você realizou alterações no cadastro. Deseja salvar antes de sair?",
    //       icon: "question",
    //       showCancelButton: true,
    //       showConfirmButton: true,
    //       confirmButtonText: "Salvar Alterações",
    //       cancelButtonText: "Descartar",
    //     }).then(async (x) => {
    //       // console.log(x);
    //       if (x.isConfirmed) {
    //         this.descartado = true;
    //         await this.$refs.entidadeForm.salvar();
    //         this.$root.$emit("bv::hide::modal", "modal-Entidades");
    //       }
    //       if (x.isDismissed && x.dismiss == "cancel") {
    //         this.descartado = true;
    //         this.$root.$emit("bv::hide::modal", "modal-Entidades");
    //       }
    //     });
    //   }
    //   // else{
    //   //         this.$bvModal.hide("modal-Entidades");
    //   // }
    // },
    async onSalvar() {
      console.log("has called on salvar");
      this.$bvModal.hide("modal-Entidades");
      this.$refs.entidadeTable.carregar();
    },
    // async salvar(evt) {
    //   evt.preventDefault();
    //   const res = await this.$refs.medForm.salvar();

    //   if (res === true) {
    //     this.$bvModal.hide("modal-Entidades");
    //     await this.$refs.medTable.carregar();
    //   }
    // },
    async onEditar(id) {
      // this.Entidade = Object.assign({}, item);
      // this.$bvModal.show("modal-Entidades");
      // await this.$nextTick();
      // this.$refs.medForm.setEntidade(item);
      this.descartado = false;
      this.entidadeEditId = id;
      this.$bvModal.show("modal-Entidades");
    },
    async closeModal(hasChanged) {
      await this.$nextTick();
      this.hasChanged = hasChanged;
      this.$bvModal.hide("modal-Entidades");
    },
  },
  computed: {
    nomeEntidade() {
      const path = this.$route.path.split("/");
      let nome =
        path[path.length - 1].charAt(0).toUpperCase() +
        path[path.length - 1].slice(1);
      if (nome.slice(nome.length - 2) == "es") nome = nome.split("es").join("");
      if (nome.charAt(nome.length - 1) == "s") nome = nome.split("s").join("");
      return nome;
    },
  },
  watch: {
    "filtros.alfabeto": {
      async handler() {},
    },
    "filtros.geral": {
      async handler(newV, oldV) {
        if (newV.trim().length >= 3 && oldV.trim() < 3) {
          if (this.filtros.alfabeto.trim()) this.filtros.alfabeto = "";
        }
      },
    },
  },
};
</script>

<style></style>
