<template>
  <div>
    <div class="row">
      <div
        class="col-12 mt-2 pl-2 font-weight-bold mb-3 text-center"
        v-if="
          entidade &&
          entidade.convenio_dependente_id &&
          entidade.convenio_dependente_id > 0
        "
      >
        <h4>
          <i class="fa fa-user" /> DEPENDENTE DE
          {{
            entidade.convenioTitular
              ? entidade.convenioTitular.razao_social
              : ""
          }}
          <div>
            <b-btn variant="danger" @click="desvincular"><b-icon-x-circle /> DESVINCULAR</b-btn>
          </div>
        </h4>
      </div>
      <div class="col-12">
        <label> Empresa</label>
        <select-paciente
          :disabled="
            entidade &&
            entidade.convenio_dependente_id &&
            entidade.convenio_dependente_id > 0
          "
          :showBotaoAdd="false"
          :showBotaoEdit="false"
          :selecionado="convenio_id"
          @paciente="handleInputConvenio"
          :tipo="['convenio']"
        ></select-paciente>
        <!-- <v-select
        :options="convenios"
        :reduce="conv=>conv.id"
        :value="convenio_id"
        @input="handleInputConvenio"
        label="razao_social"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Comece a digitar para buscar um convenio.</em
            >
          </template>
        </v-select> -->
      </div>

      <div
        class="col-12 mt-2"
        v-if="
          !entidade ||
          !entidade.convenio_dependente_id ||
          entidade.convenio_dependente_id <= 0
        "
      >
        <hr />
        <h4><i class="fa fa-users" /> Dependentes</h4>
        <b-table
          :fields="[
            { key: 'razao_social', label: 'Nome' },
            { key: 'cpf', label: 'CPF' },
            { key: 'opc', label: '' },
          ]"
          :items="deps"
        >
          <template #cell(opc)="row">
            <a href="#" @click="del(row.item)"><b-icon-trash-fill /></a>
          </template>
        </b-table>
        <hr />
        <h5>Adicionar</h5>
        <select-paciente
          :showBotaoEdit="false"
          :selecionado="dependente_id"
          @paciente="addDep"
          :tipo="['paciente']"
          @new="onNew"
          ref="selPAc"
        ></select-paciente>
      </div>
    </div>
    <b-modal
      :id="`modal-paciente-formCONVENIOPF`"
      no-enforce-focus
      hide-footer
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-header
      @close="closeModalPaciente"
      name="modal-frm-cad-conv-fil"
    >
      <PacienteFicha
        name="modal-frm-cad-conv-fil"
        id="modal-frm-cad-conv-fil"
        @close="closeModalPaciente"
        closeOnSave
        :paciente_id="0"
        :paciente="pacienteSelecionado"
      />
    </b-modal>
  </div>
</template>

<script>


// import EntidadesLib from "../../libs/EntidadesLib";
import SelectPaciente from "../common/SelectPaciente.vue";

export default {
  name:"ConvenioPFFIcha",

  components: {
    SelectPaciente,
    PacienteFicha: ()=> import ("../Entidades/Paciente/PacienteFicha.vue"),
  },
  props: {
    convenio_id: [Number, String],
    dependentes: Array,
    entidade: Object,
  },
  mounted() {
    console.log("ent", this.entidade);
    this.deps = this.dependentes;
  },
  data() {
    return {
      dependente_id: null,
      deps: [],
      pacienteSelecionado: {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },

        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
        paciente: 1,
      },
    };
  },
  watch: {
    "$props.dependentes": {
      deep: true,
      handler() {
        this.deps = this.dependentes;
        console.log("PASSOU AQUI");
        this.$forceUpdate();
      },
    },
  },
  methods: {
    // async carregaConvenios() {
    //   this.convenios = (await EntidadesLib.get("convenio")).entidades;
    // },
    del(dep) {
      this.$emit("delDep", dep);
    },
    addDep(dep) {
      if (dep) {
        // this.deps.push({id:dep.id,razao_social:dep.razao_social,cpf:dep.cpf});
        this.$emit("addDep", dep);
        this.$refs.selPAc.deselect();
      }
    },
    handleInputConvenio(convenio) {
       console.log('handleInputConvenio received', convenio, this.pacienteSelecionado);

      this.$emit("convenio", convenio);
    },
    closeModalPaciente(paciente) {
      //("close modal 1", paciente);
      if (paciente) {
        this.addDep(paciente);
      }
      this.$bvModal.hide("modal-paciente-formCONVENIOPF");
    },
    onNew() {
      this.pacienteSelecionado = {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },

        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
        paciente: 1,
      };
      // console.log("ON NEWW");
      this.$bvModal.show(`modal-paciente-formCONVENIOPF`);
    },
    desvincular(){
      this.entidade.convenio_dependente_id = null;
      this.entidade.convenioTitular = null;
    }
  },
};
</script>

<style></style>
